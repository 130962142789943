import BaseStore from './BaseStore';
import storage from 'store';
import humps from 'humps';
import config from '../config';
import { http } from '../utils/http';
import { History } from '../utils/history';
import history from '../utils/history';
import DownloadStore from './DownloadStore';
import { ROLE } from '../utils/permission';

const storeKey = {
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  INFO: 'INFO',
  PERMISSION: 'PERM',
  STORE: 'STORE',
  CONTRACTOR: 'CONTRACTOR',
  CONTRACTOR_TOKEN: 'CONTRACTOR_TOKEN',
  INSTALL_APP_ALERT: 'StateInstallAppAlert',
};

export class Auth extends BaseStore {
  constructor(props) {
    super(props);

    this.observable({
      isLoading: false,
    });
  }

  isSuperAdmin() {
    let userRole = this.getCurrentRole() ? [this.getCurrentRole()] : this.getUserInfo().role || [];
    if (userRole.filter(
      r => r.name === 'Super Admin' ||
        r.name === 'SuperAdmin' ||
        r.name === ROLE.INSTALLER_MANAGEMENT ||
        r.name === 'Installer Super Admin' ||
        r.name === ROLE.CAMPAIGN_MANAGER
    ).length > 0) return true;
    return false;
  }

  isInstallerSuperAdmin() {
    let userRole = this.getCurrentRole() ? [this.getCurrentRole()] : this.getUserInfo().role || [];
    return userRole.some(r => r.name === 'Installer Super Admin');
  }

  isAdminQChang() {
    let userRole = this.getCurrentRole() ? [this.getCurrentRole()] : this.getUserInfo().role || [];
    if (userRole.filter(r => r.name === 'Admin Q-chang').length > 0) return true;
    return false;
  }

  isAdminBU() {
    let userRole = this.getCurrentRole() ? [this.getCurrentRole()] : this.getUserInfo().role || [];
    if (userRole.filter(r => (r.name === 'Admin BU')).length > 0) return true;
    return false;
  }

  isChannelSCGHome() {
    // let userChannel = this.getUserInfo().channel || {};
    const channel = this.getChannelName();
    if (channel === 'SCGHOME Online') return true;
    return false;
  }

  isBuAccount() {
    let user = this.getUserInfo() || {};
    if (user.isBuAccount) return true;
    return false;
  }

  getToken() {
    return storage.get(storeKey.TOKEN);
  }

  setToken(token) {
    storage.set(storeKey.TOKEN, token);
  }

  getRefreshToken() {
    return storage.get(storeKey.REFRESH_TOKEN);
  }

  setRefreshToken(refreshToken) {
    storage.set(storeKey.REFRESH_TOKEN, refreshToken);
  }

  removeRefreshToken() {
    storage.remove(storeKey.REFRESH_TOKEN);
  }

  setStateInstallAppAlert(date) {
    storage.set(storeKey.INSTALL_APP_ALERT, date);
  }

  removeToken() {
    storage.remove(storeKey.TOKEN);
  }

  getPermission() {
    return storage.get(storeKey.PERMISSION) || [];
  }

  getStateInstallAppAlert() {
    return storage.get(storeKey.INSTALL_APP_ALERT);
  }

  setPermission(permission) {
    storage.set(storeKey.PERMISSION, permission);
  }

  removePermission() {
    storage.remove(storeKey.PERMISSION);
  }

  getUserInfo() {
    return storage.get(storeKey.INFO) || {};
  }

  setUserInfo(info) {
    storage.set(storeKey.INFO, info);
  }

  removeUserInfo() {
    storage.remove(storeKey.INFO);
  }

  getStore() {
    return storage.get(storeKey.STORE);
  }

  setStore(store) {
    storage.set(storeKey.STORE, store);
  }

  setStoreArea(area) {
    const store = this.getStore();
    if (store) {
      store.area = area.map(a => {
        return {
          province_id: a.provinceId,
          district_id: a.districtId,
        };
      });
      this.setStore(store);
    }
  }

  getStoreId() {
    const store = this.getStore();
    return store ? store._id : undefined;
  }

  getChannelName() {
    const store = this.getStore();
    const channel = (store && store.channel) ? store.channel : undefined
    const isSuperAdmin = this.isSuperAdmin();
    const channelName = isSuperAdmin ? 'q-chang' : channel;
    return channelName;
  }

  setStoreContractor(contractorId) {
    let store = this.getStore();
    if (store) {
      store.contractors = contractorId;
    }
    this.setStore(store);
  }

  removeStore() {
    storage.remove(storeKey.STORE);
  }

  getContractor() {
    return storage.get(storeKey.CONTRACTOR);
  }

  setContractor(contractor) {
    if (contractor.pin) {
      delete contractor.pin;
    }
    storage.set(storeKey.CONTRACTOR, contractor);
  }

  removeContractor() {
    storage.remove(storeKey.CONTRACTOR);
  }

  getContractorToken() {
    return storage.get(storeKey.CONTRACTOR_TOKEN);
  }

  setContractorToken(token) {
    storage.set(storeKey.CONTRACTOR_TOKEN, token);
  }

  removeContractorToken() {
    storage.remove(storeKey.CONTRACTOR_TOKEN);
  }

  async setUserToken({ token, refreshToken, user, permission, store } = {}) {
    this.setToken(token);
    this.setRefreshToken(refreshToken);
    delete user.exp;

    this.setUserInfo(user);
    this.setPermission(permission);
    this.setStore(store[0]);
  }

  clearToken() {
    this.removeStore();
    this.removePermission();
    this.removeUserInfo();
    this.removeToken();
    this.removeRefreshToken();
    this.removeContractor();
    this.removeContractorToken();
  }

  async checkPermission() {
    try {
      if (!this.getToken()) throw new Error('กรุณาเข้าสู่ระบบ');

      let permissions = this.getPermission();
      if (!permissions || !permissions.length) return false;
      return true;
    } catch (err) {
      this.clearToken();
      return false;
    }
  }

  async hasPermission(permission) {
    try {
      let permissions = this.getPermission();
      if (!permissions || !permissions.length) return false;
      return permissions.includes(permission);
    } catch (err) {
      this.clearToken();
      return false;
    }
  }

  async authenticate(params) {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      let response = await http.auth(
        `${config.api.sims}/v1/auth`,
        params.username,
        params.password
      );

      this.isLoading = false;

      if (!(response && response.data) || response.status !== 200) {
        throw new Error('Username หรือ Password ไม่ถูกต้อง!');
      }

      await this.setUserToken({
        token: response.data.user.accessToken,
        refreshToken: response.data.user.refreshToken,
        user: { ...response.data.user, email: response.data.user.user_name || '' },
        permission: (response.data.permissions || []).map(p => p.permissionName),
        store: response.data.stores,
      });

      return params.redirectUrl || `${config.publicUrl}/`;
    } catch (error) {
      this.isLoading = false;

      throw error;
    }
  }

  async authenticateWithGoogle(data) {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      let response = await http.post(
        `${config.api.sims}/v1/auth/google`,
        {
          token: data.token
        }
      );

      this.isLoading = false;

      if (!(response && response.data) || response.status !== 200) {
        throw new Error('เกิดความผิดพลาดในการเข้าสู่ระบบ!');
      }

      const currentRole =
        response.data.user &&
        ((response.data.user.role || []).find(
          (role) => role.name === "Super Admin"
        ) || response.data.user.role[0]);

      await this.setUserToken({
        token: response.data.user.accessToken,
        refreshToken: response.data.user.refreshToken,
        user: { ...response.data.user, currentRole, email: data.email, displayName: data.displayName },
        permission: (response.data.permissions || []).map(p => p.permissionName),
        store: response.data.stores,
      });

      let userInfo = this.getUserInfo() || {};
      let responseVersion = await http.get(`${config.api.sims}`);
      await this.setUserInfo({ ...userInfo, apiVersion: responseVersion.data.message });

      return `${config.publicUrl}/`;
    } catch (error) {
      this.isLoading = false;

      throw error;
    }
  }

  signOut() {
    const checkHasContractor = this.getContractor()
    this.clearToken();
    if (checkHasContractor) {
      History.replace(`${config.publicUrl}/contractors/signin`);
    } else {
      History.replace(`${config.publicUrl}/signin`);
    }
  }

  checkAdminSignedIn() {
    let store = this.getStore();
    let permission = this.getPermission();
    let token = this.getToken();

    if (store && permission && token) {
      return true;
    }

    return false;
  }

  async contractorAuthenticate(phone, pin) {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      let response = await http.auth(
        `${config.api.sims}/v1/auth/contractor`,
        phone,
        pin
      );

      this.isLoading = false;

      if (!(response && response.data) || response.status !== 200) {
        throw new Error('Pin ไม่ถูกต้อง!');
      }

      if (response && response.data && response.data.contractor && response.data.token) {
        this.setContractorToken(response.data.token);
        const contractor = response.data.contractor;
        // const signUrl = contractor.profile_pic_info && await DownloadStore.getSignUrl(contractor.profile_pic_info.file, contractor.profile_pic_info.path);
        // if (signUrl) {
        //   contractor.profile_pic = signUrl.fileUrl;
        // }
        this.setContractor(contractor);
        return `${config.publicUrl}/contractors/${response.data.contractor._id}/calendar`;
      } else {
        throw new Error('ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      this.isLoading = false;

      throw error;
    }
  }

  contractorSignOut() {
    this.removeContractor();
    this.removeContractorToken();
    History.replace(`${config.publicUrl}/contractors/signin`);
  }

  checkContractorSignedIn(paramContractorId) {
    const contractor = this.getContractor();
    if (!contractor) {
      return false;
    }

    if (paramContractorId && paramContractorId !== contractor._id) {
      this.removeContractor();
      return false;
    }

    return true;
  }

  getContractorId() {
    const contractor = this.getContractor();
    if (!contractor) return undefined;
    return contractor._id;
  }

  getUserRole() {
    return this.getUserInfo().role || [];
  }

  getCurrentRole() {
    return this.getUserInfo().currentRole;
  }

  getUsernameOrEmail() {
    return this.getUserInfo().user_name ?
      this.getUserInfo().user_name : this.getUserInfo().email;
  }

  getUserAndSource() {
    let user = "";
    let source = "";
    const pathname = history.location.pathname;
    if (pathname.includes('/sims/contractors')) {
      const contractor = this.getContractor();
      const teamName = contractor.team_name ? contractor.team_name : '';
      user = `${teamName}`;
      source = "sims_contractor";
    } else {
      user = this.getUsernameOrEmail();
      source = "sims_admin";
    }
    return {
      user,
      source
    }
  }

  async setPermissions(permissionUuid) {
    if (this.isLoading) return;

    try {
      this.isLoading = true;

      let response = await http.get(
        `${config.api.sims}/v1/auth/permissions/${permissionUuid}`
      );

      this.isLoading = false;
      const permissions = response && response.data && response.data.data && response.data.data.permissions;

      if (!(permissions) || response.status !== 200) {
        throw new Error('ไม่พบ PERMISSION');
      }

      const permission = permissions.map((value) => value.permissionName) || [];

      await this.setPermission(permission);
      let userInfo = this.getUserInfo() || {};
      let userRole = this.getUserInfo().role || [];
      let currentRole = userRole.find(value => value.uuid === permissionUuid);
      await this.setUserInfo({ ...userInfo, currentRole });
    } catch (error) {
      this.isLoading = false;

      throw error;
    }
  }

  async setAccessTokenWithRefresh() {
    try {
      const refreshTokenStore = this.getRefreshToken();
      if (refreshTokenStore) {
        let { data } = await http.getAccessToken({
          token: refreshTokenStore
        });

        const { accessToken } = data || {};

        if (accessToken) {
          this.setToken(accessToken);
          return data;
        }
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getAPIVersion() {
    try {
      let response = await http.get(`${config.api.sims}`);
      return response.data.message;
    } catch (error) {
      this.isLoading = false;

      throw error;
    }
  }

}

export default new Auth();
